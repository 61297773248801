<template>
    <main class="paybyrd">
		
<section>
	<div class="content-leads">
		<div class="content-leads-itens">
			<div class="content-leads-itens-bg">
				<div class="container">
					<div class="content-leads-itens-header">
						<div class="content-leads-itens-header-div">
							<router-link class="btn-return" :to="{name: 'home'}">
								<svg xmlns="http://www.w3.org/2000/svg" width="9.586" height="10.582" viewBox="0 0 9.586 10.582">
									<path d="M-3.006-7.547,4.33-4.582v2.3L-5.256-6.727V-8.437l9.586-4.43v2.3Z"
										transform="translate(5.256 12.867)" />
								</svg>
								<p>voltar</p>
							</router-link>
							<h1 class="title">
								{{campaign.name}}
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="content-notices container">
		<div :style="`background: url(${campaign.image_path});`" class="content-notices-box box-detail">
		</div>
		<div class="content-notices-box1">
			<p>campanha válida de:</p>
			<h3>{{campaign.date_start_at|moment('DD/MM/YYYY')}} - {{campaign.date_end_at|moment('DD/MM/YYYY')}}</h3>
			<button>
				<img class="img-fluid" src="@/assets/img/leads/img-trophy.png" alt="">
				 {{campaign.point_by_rewards}} pts
			</button>
			<img class="img-fluid" :src="campaign.image_reward_path" alt="">
			<p class="sub-title">{{campaign.reward_description}}</p>
		</div>
	</div>
	<div class="content-noticia-text container" v-html="campaign.description">		
	</div>
	<!--TABELA-->
	<div class="content-leads-itens-body">
		<div class="container">
			<div class="content-leads-title">
				<img class="img-fluid" src="@/assets/img/leads/img-rank.png" alt="">
				<h2>Qualificação</h2>
			</div>
			<div class="content-table">
				<table class="content-table-element">
					
					<template v-for="(balance, key) in campaign.balances">
						<tr :key="key">
							<td class="number" style="width: 5%;">
								<span v-if="parseInt(balance.balance) >= parseInt(campaign.point_by_rewards)" class="icon-trophy">
									<img class="img-fluid" src="@/assets/img/leads/trophy.png" alt="image trophy" title="image trophy"/>
								</span>
								<div>{{key + 1}}</div>
							</td>
							<td class="name" style="width: 70%;">{{balance.user.name}}</td>
							<td class="code" style="width: 10%;">{{balance.user.code ? balance.user.code : '--'}}</td>
							<td class="point" style="width: 15%;">{{balance.balance}} <span>pts</span></td>
						
						</tr>
					</template>
					
					<tr v-if="campaign.user_rank && campaign.user_rank.rank > 10 && Object.keys(user).length" class="last-table">
						<td class="number" style="width: 5%;">							
							<div>
								{{campaign.user_rank.rank}}º
							</div>
						</td>
						<td class="name" style="width: 70%;">
							<div>{{user.name}}</div>
						</td>
							<td class="code" style="width: 10%;">
							<div>{{user.code}}</div>
						</td>
						<td class="point" style="width: 15%;">
							<div>{{ campaign.user_rank.balance }}<span>pts</span></div>
						</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</section>

	</main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';

export default {
    data(){
        return{
            campaign : {},            
        }
    },
    computed:{
        ...mapGetters('User', ['user']),
    },
    methods:{
        ...mapActions('App', ['loading']), 
		...mapActions('User', ['getUser']), 
        async getDetails(){
            this.loading(true);
            await axios({
                method: 'GET',
                url: '/api/v1/campaign/detail',
                params: {
                   slug: this.$route.params.slug,
				   user_id: this.user.id
                }             
            }).then(response => response.data.data)
            .then((data) => {  
               this.loading(false); 
			   	
			   if(!data || Object.keys(data).length == 0){
				   document.location.href = '/';
			   }

               this.campaign = data;               
            })          
        },        
    },
    async mounted(){        		        
		this.$nextTick(async () => {					
			await this.getDetails();
		   if(Object.keys(this.user).length == 0){
              await this.getUser();
           }		              
        })
    }
}
</script>
<style scoped>
.content-leads-itens-bg{
	background-image: url('~@/assets/img/leads/bg-campaign.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.content-notices-box1 button {
	width: 200px;
}
.box-detail{
	background-position: left!important;
}
</style>